import { Box, Stack, styled, Paper, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as LevelIcon } from "../assets/resources/level.svg";
import { Trans } from "@lingui/macro";
import { MemberLevelDto } from "../apiclient/MemberAPI";
import { hexToRGBA } from "../utils/themeStyling";
import { CircularLevel } from "./progress";
import { FireworksAnimation } from "./fireworks";
import { useState } from "react";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: "transparent",
	...theme.typography.body2,
	textAlign: "center",
	color: theme.palette.text.secondary,
	boxShadow: "0 0",
}));

interface Props {
	memberLevel?: MemberLevelDto | null;
	nextLevel?: MemberLevelDto | null;
}

export function LevelBox(props: Props) {
	let bgColor = "#084E6DEE";
	let strokeColor = "#FFFFFF";
	let member = " Member";
	switch (props.memberLevel?.level) {
		case 0: // Member
			bgColor = "#e7e8e9";
			strokeColor = "#000000";
			member = "";
			break;
		case 1: // Bronze
			bgColor = "#d2d3d5";
			strokeColor = "#000000";
			break;
		case 2: // Silver
			bgColor = "#bbbdc0";
			strokeColor = "#000000";
			break;
		case 3: // Gold
			bgColor = "#a6a8ac";
			strokeColor = "#000000";
			break;
		case 4: // Platinum
			bgColor = "#909297";
			strokeColor = "#000000";
			break;
		default:
			break;
	}
	const [fireworkVal, setFireworkVal] = useState(14);
	const hasNextLevelPoint = props.memberLevel?.pointsUntilNextLevel !== undefined && props.memberLevel?.pointsUntilNextLevel > 0;
	const handleFireworkVal = (val: any) => {
		if (props.nextLevel === undefined || props.nextLevel === null) setFireworkVal(val);
	};

	if (props.nextLevel === undefined || props.nextLevel === null)
		return (
			<Box sx={{ height: "unset", margin: "unset", textAlign: "unset" }}>
				<Stack spacing={0} sx={{}}>
					<Item
						sx={{
							pt: "27px",
							pl: "16px",
							pr: "16px",
							borderBottomRightRadius: 0,
							borderBottomLeftRadius: 0,
							backgroundColor: (theme) => {
								if (bgColor.length === 0) return hexToRGBA(theme.palette.background.paper, "0.1");
								return bgColor;
							},
						}}
					>
						<Stack direction="row" spacing={0}>
							<Item
								sx={{
									backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
								}}
							>
								<LevelIcon stroke={strokeColor} />
							</Item>
							<Item
								sx={{
									color: (theme) => theme.palette.secondary.main,
									mt: "-3px",
									fontWeight: "600",
									fontSize: "20px",
									backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
									pl: 1,
								}}
							>
								<Trans>
									{props.memberLevel?.displayName}
									{member}
								</Trans>
							</Item>
						</Stack>
					</Item>
					<Item
						sx={{
							pt: "3px",
							pl: "16px",
							pr: "16px",
							borderRadius: 0,
							backgroundColor: bgColor,
						}}
					></Item>
					<Item
						sx={{
							pt: hasNextLevelPoint ? "10px" : "24px",
							pb: "24px",
							pl: "16px",
							pr: "16px",
							borderTopRightRadius: 0,
							borderTopLeftRadius: 0,
							backgroundColor: bgColor,
							zIndex: "unset",
						}}
					>
						<Box
							sx={{
								display: "block",
								textAlign: "center",
							}}
						>
							<Item sx={{}}>
								<CircularLevel onReset={handleFireworkVal} visible value={100} />
							</Item>
						</Box>
						<Box
							sx={{
								display: "flex",
								"& > *:not(:last-child)": { marginRight: "3px" },
							}}
						>
							{fireworkVal > 0 && <FireworksAnimation value={fireworkVal} />}
						</Box>
					</Item>
				</Stack>
			</Box>
		);

	var progressValue = ~~((props.memberLevel?.progress ?? 0) * 100);
	return (
		<Box sx={{ height: "unset", margin: "unset", textAlign: "unset" }}>
			<Stack spacing={0} sx={{}}>
				<Item
					sx={{
						pt: "27px",
						pl: "16px",
						pr: "16px",
						borderBottomRightRadius: 0,
						borderBottomLeftRadius: 0,
						backgroundColor: (theme) => {
							if (bgColor.length === 0) return hexToRGBA(theme.palette.background.paper, "0.1");
							return bgColor;
						},
					}}
				>
					<Stack
						direction="row"
						sx={{
							justifyContent: "space-between",
						}}
					>
						<Item
							sx={{
								color: (theme) => theme.palette.secondary.main,
								mt: "-8px",
								fontWeight: "600",
								fontSize: "20px",
								backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
							}}
						>
							<Trans>
								{props.memberLevel?.displayName}
								{member}
							</Trans>
						</Item>
						<Item sx={{ backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))" }}>
							<InfoIcon
								stroke={strokeColor}
								sx={{
									fontSize: "14px",
									color: (theme) => {
										return theme.palette.background.paper;
									},
								}}
							/>
						</Item>
					</Stack>
				</Item>
				<Item
					sx={{
						pt: "3px",
						pl: "16px",
						pr: "16px",
						borderRadius: 0,
						backgroundColor: bgColor,
					}}
				>
					<Stack direction="row" spacing={0}>
						<Item
							sx={{
								backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
							}}
						>
							<LevelIcon stroke={strokeColor} />
						</Item>
						<Item
							sx={{
								color: (theme) => theme.palette.secondary.main,
								mt: "-3px",
								fontSize: (theme) => theme.typography.subtitle2.fontSize,
								fontWeight: (theme) => theme.typography.subtitle1.fontWeight,
								backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
								pl: 1,
							}}
						>
							<Trans>Progress to {props.nextLevel?.displayName}</Trans>
						</Item>
					</Stack>
				</Item>
				<Item
					sx={{
						pt: hasNextLevelPoint ? "10px" : "24px",
						pb: "24px",
						pl: "16px",
						pr: "16px",
						borderTopRightRadius: 0,
						borderTopLeftRadius: 0,
						backgroundColor: bgColor,
						zIndex: "unset",
					}}
				>
					<Box
						sx={{
							display: "block",
							textAlign: "center",
						}}
					>
						<Item sx={{}}>
							<CircularLevel onReset={handleFireworkVal} visible value={progressValue} />
						</Item>
					</Box>
					<Box
						sx={{
							display: "flex",
							"& > *:not(:last-child)": { marginRight: "3px" },
						}}
					>
						{hasNextLevelPoint && (
							<Typography sx={{ height: "30px", fontSize: "12px", mb: "1.57px" }}>
								<Trans>
									You're just {props.memberLevel?.pointsUntilNextLevel} {props.memberLevel?.pointDiscriminator} away from reaching the next level
								</Trans>
							</Typography>
						)}
					</Box>
				</Item>
			</Stack>
		</Box>
	);
}
