import React from "react";
import { BonusResponseDTO, GiftResponseDTO, Languages, Product, WidgetThemes } from "../types/type";
import {
	AuthenticationTypes,
	BenefitTypes,
	MainMenu,
	WidgetMode,
	WidgetSize,
	TopbarMenu,
	BenefitReleaseModes,
	ProfilePageItems,
	SettingPageItems,
	LanguageCodes,
	ThemeCodes,
	LoginButtonStyle,
} from "../types/enum";
import { IThemeSettings, WidgetFunctions } from "../types/interface";
import { CountryCode } from "libphonenumber-js/core";

export interface IntegrationSettings {
	authRequiredAccount: boolean;
	authRequiredBirthdate: boolean;
	authRequiredCard: boolean;
	authRequiredEmail: boolean;
	authenticationTypes: AuthenticationTypes[];
	benefits: BenefitTypes[];
	container: string;
	containerThemeSettings: IThemeSettings | null;
	customer: string;
	defaultLanguage: LanguageCodes;
	defaultTheme: ThemeCodes;
	displayName: string;
	emailOnConsent: boolean;
	enableCardEdit: boolean;
	enableConsentEdit: boolean;
	enableDownloadReceipt: boolean;
	enableProfileEdit: boolean;
	enableReleaseBonus: boolean;
	enableReleaseGift: boolean;
	enableReleaseOffer: boolean;
	languages: Languages[];
	loginButtonStyle: LoginButtonStyle;
	menu: MainMenu[];
	noReleasableWithText: boolean;
	optionalBirthdateOnConsent: boolean;
	palette: string;
	phoneCountryCode: CountryCode | "";
	popupTimeout: number;
	postLoginWidgetMode: WidgetMode | null;
	postLoginWidgetSize: WidgetSize | null;
	product: Product;
	profilePageItems: ProfilePageItems[];
	programId: string;
	programDefaultTag: string;
	releaseMode: BenefitReleaseModes;
	releaseTimerMax: number;
	settingPageItems: SettingPageItems[];
	showLevel: boolean;
	storageId?: string;
	terminalGUID: string;
	themes: WidgetThemes[];
	topbar: TopbarMenu[];
	topbarOnLoginRouter: boolean;
	onEvent: (object: any) => void;
	onBonusReserved: (reference: string, amount: string) => Promise<BonusResponseDTO>;
	getBonusReleased: (reference: string) => BonusResponseDTO;
	deleteBonusReservation: (reference: string) => Promise<boolean>;
	onGiftReserved: (reference: string, giftId: string, amount: string) => Promise<GiftResponseDTO>;
	getGiftReleased: (reference: string, giftId: string) => GiftResponseDTO;
	onOfferReserved: (reference: string, code: string) => BonusResponseDTO;
	getOfferReleased: (reference: string) => BonusResponseDTO;
	onWidgetInitialize: (widgetFunctions: WidgetFunctions) => void;
}

export const IntegrationSettingsDefaults: IntegrationSettings = {
	authRequiredAccount: false,
	authRequiredBirthdate: false,
	authRequiredCard: false,
	authRequiredEmail: false,
	authenticationTypes: [AuthenticationTypes.Vipps, AuthenticationTypes.Mobile],
	benefits: [BenefitTypes.Bonus, BenefitTypes.Gift, BenefitTypes.Offer],
	container: "#loyaltywidget",
	containerThemeSettings: null,
	customer: "loyall",
	defaultLanguage: LanguageCodes.en,
	defaultTheme: ThemeCodes.Dark,
	displayName: "loyall",
	emailOnConsent: true,
	enableCardEdit: false,
	enableConsentEdit: false,
	enableDownloadReceipt: false,
	enableProfileEdit: true,
	enableReleaseBonus: false,
	enableReleaseGift: false,
	enableReleaseOffer: false,
	languages: [
		{ Code: LanguageCodes.en, Description: "English", IsActive: true },
		{ Code: LanguageCodes.no, Description: "Norwegian", IsActive: true },
		{ Code: LanguageCodes.sv, Description: "Swedish", IsActive: true },
		{ Code: LanguageCodes.da, Description: "Danish", IsActive: true },
		{ Code: LanguageCodes.de, Description: "German", IsActive: true },
		{ Code: LanguageCodes.el, Description: "Greek", IsActive: true },
	],
	loginButtonStyle: LoginButtonStyle.Single,
	menu: [MainMenu.Benefits, MainMenu.Profile, MainMenu.Purchases, MainMenu.Settings, MainMenu.Support],
	noReleasableWithText: false,
	optionalBirthdateOnConsent: false,
	palette: "",
	phoneCountryCode: "",
	popupTimeout: 10000,
	postLoginWidgetMode: WidgetMode.Open,
	postLoginWidgetSize: WidgetSize.Main,
	product: { Name: undefined, Discount: undefined, DiscountPercentage: undefined, IsInSale: undefined, Price: undefined, Currency: undefined },
	profilePageItems: [ProfilePageItems.Profile, ProfilePageItems.Consent, ProfilePageItems.Cards],
	programId: "0",
	programDefaultTag: "",
	releaseMode: BenefitReleaseModes.CreateDiscountCode,
	releaseTimerMax: 0,
	settingPageItems: [SettingPageItems.Language, SettingPageItems.Theme, SettingPageItems.Profile],
	showLevel: false,
	storageId: "loyalty",
	terminalGUID: "",
	themes: [
		{ Code: ThemeCodes.Loyall, IsActive: true },
		{ Code: ThemeCodes.Retro, IsActive: true },
		{ Code: ThemeCodes.Dark, IsActive: true },
		{ Code: ThemeCodes.Light, IsActive: true },
	],
	topbar: [TopbarMenu.Resize, TopbarMenu.Minimize, TopbarMenu.Close],
	topbarOnLoginRouter: true,
	onEvent: (object: any) => {},
	onBonusReserved: async (reference: string, amount: string) => {
		const temp: BonusResponseDTO = { Message: "", Result: false, Value: "" };
		return Promise.resolve(temp);
	},
	getBonusReleased: (reference: string) => {
		const temp: BonusResponseDTO = { Message: "", Result: false, Value: "" };
		return temp;
	},
	deleteBonusReservation: (reference: string) => {
		return Promise.resolve(false);
	},
	onGiftReserved: async (reference: string, giftId: string, amount: string) => {
		const temp: GiftResponseDTO = { Message: "", Result: false, Value: "", Reference: "" };
		return Promise.resolve(temp);
	},
	getGiftReleased: (reference: string, giftId: string) => {
		const temp: GiftResponseDTO = { Message: "", Result: false, Value: "", Reference: "" };
		return temp;
	},
	onOfferReserved: (reference: string, code: string) => {
		const temp: BonusResponseDTO = { Message: "", Result: false, Value: "" };
		return temp;
	},
	getOfferReleased: (reference: string) => {
		const temp: BonusResponseDTO = { Message: "", Result: false, Value: "" };
		return temp;
	},
	onWidgetInitialize: (widgetFunctions: WidgetFunctions) => {},
};

export const IntegrationSettingsContext = React.createContext(IntegrationSettingsDefaults);
